<template>
    <v-container>
      <v-col cols="12" lg="1" align-self="center">
        <v-btn elevation="0" color="" class="bg-success text-white text-center font-weight-bold my-4" to="account" flat>
          <v-icon>mdi-keyboard-backspace</v-icon> Back
        </v-btn>
      </v-col>
      <v-row>
        <h1 class="mb-3">Billing and Support</h1>
        <v-col cols="12" class="fw-bold">
          <p>
            For any billing-related concerns or queries about your subscription, please contact our billing support team at
            <a href="mailto:billing@supersavers.au" target="_blank" rel="noopener noreferrer">
              billing@supersavers.au
            </a>.
          </p>
  
          <h2>Common Queries</h2>
          <p>If you have experienced issues such as double payments, extra charges, or any discrepancies in your billing, our dedicated team is here to assist you promptly. Kindly provide detailed information about your concern for a quicker resolution.</p>
  
          <h2>Account Issues</h2>
          <p>If anything goes wrong with your account, such as encountering a bug, glitch, or suspecting a data breach, please reach out to our support team at
            <a href="mailto:support@supersavers.au" target="_blank" rel="noopener noreferrer">
              support@supersavers.au
            </a>.
          </p>
  
          <p>We take your account security seriously and will investigate and address any issues promptly to ensure a smooth and secure user experience.</p>

        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  export default {
    metaInfo: {
  // Page Title
  title: 'Supersavers | Billing Support',

  // Meta Tags
  meta: [
    { charset: 'utf-8' }, // Character set
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' }, // Responsive design

    // SEO Meta Tags
    {
      name: 'description',
      content: 'Contact Supersavers Billing Support for assistance with billing-related concerns, subscription queries, and resolution of issues such as double payments or extra charges.'
    }, // Page description
    {
      name: 'keywords',
      content: 'Supersavers, billing support, subscription queries, double payments, extra charges, account billing, support team'
    }, // Keywords for SEO

    // Open Graph (OG) Meta Tags
    { property: 'og:title', content: 'Supersavers | Billing Support' }, // Open Graph title
    {
      property: 'og:description',
      content: 'Contact Supersavers Billing Support for assistance with billing-related concerns, subscription queries, and resolution of issues such as double payments or extra charges.'
    }, // Open Graph description
    { property: 'og:image', content: 'https://supersavers.au/banner.png' }, // Open Graph image
    { property: 'og:url', content: 'https://supersavers.au/billing-support' }, // Open Graph URL
    { property: 'og:type', content: 'website' }, // Open Graph type (e.g., article, website)

    // Twitter Meta Tags
    { name: 'twitter:title', content: 'Supersavers | Billing Support' }, // Twitter title
    {
      name: 'twitter:description',
      content: 'Contact Supersavers Billing Support for assistance with billing-related concerns, subscription queries, and resolution of issues such as double payments or extra charges.'
    }, // Twitter description
    { name: 'twitter:image', content: 'https://supersavers.au/banner.png' }, // Twitter image
    { name: 'twitter:card', content: 'summary_large_image' }, // Twitter card type
  ],
}

  }
  </script>
  