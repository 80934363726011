<template>
    <v-container>
      <v-col cols="12" lg="1" align-self="center">
        <v-btn elevation="0" color="" class="bg-success text-white text-center font-weight-bold my-4" to="account" flat>
          <v-icon>mdi-keyboard-backspace</v-icon> Back
        </v-btn>
      </v-col>
      <v-row>
        <h1 class="mb-3">Privacy Policy</h1>
        <v-col cols="12" class="fw-bold">
          <p>
            Your privacy is of utmost importance to us. This privacy policy outlines how Supersavers collects, uses, and protects any information that you provide when using our website,
            <a href="https://supersavers.au" target="_blank" rel="noopener noreferrer">
              https:/supersavers.au
            </a>
            , and other sites that we own and operate.
          </p>
          <p>This policy is effective as of 1 December 2023.</p>
  
          <h2>Data Collected</h2>
          <p>We collect and securely store user data, such as email and password, in our database. Please note that no credit card details are stored on our database. We utilize <span class="bg-primary p-1 text-white fw-bold">Stripe</span> as our payment platform, and subscription information will be stored on our database.</p>
  
          <h2>Product Data</h2>
          <p>We gather information on items recorded in your cart, your purchased list, and your notification list at regular intervals for marketing purposes and integration with third-party apps. It's important to note that this data may be shared with other companies in an anonymous and generic form, ensuring your personal identity remains protected.</p>
  
          <h2>Analytic Data</h2>
          <p>For monitoring and predictive purposes, we record anonymous user retention and session data. This information is not directly linked to your account and is used to enhance the overall user experience without compromising individual privacy.</p>
  
          <h2>Preferences</h2>
          <p>You have the right to disable mobile notifications and email marketing notifications under your account settings. We respect your communication preferences and will ensure compliance with your choices.</p>
  
          <h2>Data Sharing</h2>
          <p>We may share data with Woolworths, Coles, IGA, and other stores for the purpose of enhancing your shopping experience. However, it's important to note that such data sharing is not confirmed, and we commit to keeping your information secure and private.</p>
  
          <h2>Compliance</h2>
          <p>We follow compliance laws related to not storing credit card details, ensuring the highest standards of security for your financial information.</p>
  
          <h2>Account Deactivation</h2>
          <p>Please note that user accounts will remain in the system for 99 years. If you wish to deactivate your account, please contact our support team.</p>
  
          <h2>Cookies and Analytics</h2>
          <p>We use cookies to store your product information and collect analytical data for the purpose of improving our services and user experience.</p>
          
          <h2>Billing Issues and Account Support</h2>
          <p>If you face any issues with your account, or your billing please visit the <a href="/billing-support">billing</a> documentation</p>
         
          <h2>Disclaimer</h2>
          <p>We are not responsible for any breach of data that was caused unintentionally. Moreover, we try to follow up-to-date security practices. Our AI can also make mistakes with the hope that it will improve over time and with future updates</p>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  export default {
    metaInfo: {
  // Page Title
  title: 'Supersavers | Privacy Policy',

  // Meta Tags
  meta: [
    { charset: 'utf-8' }, // Character set
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' }, // Responsive design

    // SEO Meta Tags
    {
      name: 'description',
      content: 'Learn about how Supersavers respects and protects your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information.'
    }, // Page description
    {
      name: 'keywords',
      content: 'Supersavers, privacy policy, data protection, user information, online platform, security measures'
    }, // Keywords for SEO

    // Open Graph (OG) Meta Tags
    { property: 'og:title', content: 'Supersavers | Privacy Policy' }, // Open Graph title
    {
      property: 'og:description',
      content: 'Learn about how Supersavers respects and protects your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information.'
    }, // Open Graph description
    { property: 'og:image', content: 'https://supersavers.au/banner.png' }, // Open Graph image
    { property: 'og:url', content: 'https://supersavers.au/privacy-policy' }, // Open Graph URL
    { property: 'og:type', content: 'website' }, // Open Graph type (e.g., article, website)

    // Twitter Meta Tags
    { name: 'twitter:title', content: 'Supersavers | Privacy Policy' }, // Twitter title
    {
      name: 'twitter:description',
      content: 'Learn about how Supersavers respects and protects your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information.'
    }, // Twitter description
    { name: 'twitter:image', content: 'https://supersavers.au/banner.png' }, // Twitter image
    { name: 'twitter:card', content: 'summary_large_image' }, // Twitter card type
  ],
}


  }
  </script>